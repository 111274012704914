import React, { useState, useEffect } from "react";

const NoInternet = (props) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>
              5<span>0</span>3
            </h1>
          </div>
          <h2>
            Unable to process the request due to a temporary internet
            disconnection.
          </h2>
        </div>
      </div>
    );
  }
};

export default NoInternet;
