import React, { useEffect } from "react";
import wish from "../../../Images/maskgroup-2.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import { jwtDecode } from "jwt-decode";
import CartCounter from "./CartCounter";

export default function CartBox() {
  const { wishlist_items_count, userAccessToken, cartCount } = useSelector(
    (state) => state.stateVals
  );

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userAccessToken) {
      navigate("/");
    } else {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const decoded = jwtDecode(userAccessToken);
      if (currentTime > decoded.exp) {
        toast.error("Request time out!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        userActions.logOut("userLogOut");

        navigate("/", { replace: true });
      }
    }
  }, []);

  return (
    <li className="cart-box-d">
      <ul className="cart-box">
        <li>
          <Link to="/wishlist">
            <img src={wish} alt="Wish List" />
            <span>{wishlist_items_count}</span>
          </Link>
        </li>
        <li>
          <CartCounter counter={cartCount} />
        </li>
      </ul>
    </li>
  );
}
