import React, { useEffect, useState } from "react";
import "./categories.css";

import $ from "jquery";
import UserListService from "../../../services/user-list.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
window.jQuery = $;
window.$ = $;

export default function Categories() {
  const [headerCategory, setHeaderCategory] = useState([]);
  const navigate = useNavigate();
  const toggleNav = () => {
    var navIsVisible = !$(".cd-dropdown").hasClass("dropdown-is-active")
      ? true
      : false;
    $(".cd-dropdown").toggleClass("dropdown-is-active", navIsVisible);
    $(".cd-dropdown-trigger").toggleClass("dropdown-is-active", navIsVisible);
    if (!navIsVisible) {
      $(".cd-dropdown").one(
        "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
        function () {
          $(".has-children ul").addClass("is-hidden");
          $(".move-out").removeClass("move-out");
          $(".is-active").removeClass("is-active");
        }
      );
    }
  };

  const triggered = (e) => {
    e.preventDefault();
    toggleNav();
  };
  const { userAccessToken } = useSelector((state) => state.stateVals);

  useEffect(() => {
    const loadCatagries = async () => {
      try {
        const res = await UserListService.listheaderCategories(userAccessToken);

        setHeaderCategory(res?.data?.data);
        const script = document.createElement("script");

        script.src =
          "https://res.cloudinary.com/sparkcloudsforewards/raw/upload/v1720003425/rewardJS/jquery.menu-aim_wax9yn.js"; // Replace with your script URL
        script.async = true;
        script.id = "scriptMenu";

        script.onload = () => {
          //on desktop - differentiate between a user trying to hover over a dropdown item vs trying to navigate into a submenu's contents
          var submenuDirection = !$(".cd-dropdown-wrapper").hasClass(
            "open-to-left"
          )
            ? "right"
            : "left";
          $(".cd-dropdown-content").menuAim({
            activate: function (row) {
              $(row).children().addClass("is-active").removeClass("fade-out");
              if ($(".cd-dropdown-content .fade-in").length == 0)
                $(row).children("ul").addClass("fade-in");
            },
            deactivate: function (row) {
              $(row).children().removeClass("is-active");
              if (
                $("li.has-children:hover").length == 0 ||
                $("li.has-children:hover").is($(row))
              ) {
                $(".cd-dropdown-content")
                  .find(".fade-in")
                  .removeClass("fade-in");
                $(row).children("ul").addClass("fade-out");
              }
            },
            exitMenu: function () {
              $(".cd-dropdown-content")
                .find(".is-active")
                .removeClass("is-active");
              return true;
            },
            submenuDirection: submenuDirection,
          });
        };

        document.body.appendChild(script);
      } catch (error) {
        console.log(error);
        setHeaderCategory([]);
      }
    };

    loadCatagries();
    return () => {
      const script = document.getElementById("scriptMenu");
      if (script) {
        document.body.removeChild(script); // Clean up on component unmount
      }
    };
  }, []);

  $(".has-children")
    .children("a")
    .on("click", function (event) {
      //prevent default clicking on direct children of .has-children
      event.preventDefault();
      var selected = $(this);
      selected
        .next("ul")
        .removeClass("is-hidden")
        .end()
        .parent(".has-children")
        .parent("ul")
        .addClass("move-out");
    });

  //submenu items - go back link
  $(".go-back").on("click", function () {
    var selected = $(this),
      visibleNav = $(this).parent("ul").parent(".has-children").parent("ul");
    selected
      .parent("ul")
      .addClass("is-hidden")
      .parent(".has-children")
      .parent("ul")
      .removeClass("move-out");
  });

  return (
    <li className="categories-dropdown dropdown">
      <div className="cd-dropdown-wrapper">
        <a
          className="btn-secondary custom-secondary cd-dropdown-trigger"
          href="/"
          onClick={triggered}
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" y="0.5" width="4" height="4" stroke="" />
            <rect x="8.5" y="0.5" width="4" height="4" stroke="" />
            <rect x="0.5" y="7.5" width="4" height="4" stroke="" />
            <rect x="8.5" y="7.5" width="4" height="4" stroke="" />
          </svg>{" "}
          All Categories
        </a>
        <nav className="cd-dropdown">
          <h2>All Categories</h2>
          <a href="/" onClick={triggered} className="cd-close">
            Close
          </a>
          <ul className="cd-dropdown-content">
            {headerCategory.length ? (
              headerCategory?.map((category) => (
                <li className="has-children" key={category.id}>
                  <a href="#">{category?.name}</a>

                  <ul className="cd-secondary-dropdown is-hidden">
                    <li className="go-back">
                      <a href="#0">Menu</a>
                    </li>
                    {category?.sub_categories?.map((subCategory) => (
                      <li key={subCategory.id}>
                        <div
                          className="categories-item"
                          onClick={() => {
                            navigate(
                              `/view-products/${category?.name}/${category.id}/${subCategory.id}`
                            );
                          }}
                        >
                          <img src={subCategory.image} alt="icon" />

                          <div>
                            <label>{subCategory.name}</label>
                            <span>{subCategory.product_count} Products</span>
                          </div>
                        </div>
                        <div className="clearfix"></div>

                        {/* <a href="#">{subCategory.name}</a> */}
                        {/* <ul className="is-hidden"> */}
                        {/* <li className="go-back">
                            <a href="#0">{subCategory.name}</a>
                          </li> */}
                        {/* <li className="see-all">
                            <a href="#">All Tops</a>
                          </li> */}
                        {/* {subCategory?.products?.map((product) => (
                            <li key={product.id}>
                              <Link
                                to={`/product-detail/${product.id}`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={product.name}
                              >
                                {product.name}
                              </Link>
                            </li>
                          ))}
                        </ul> */}
                      </li>
                    ))}

                    {/* <li className="see-all">
                      <a href="#">All Clothing</a>
                    </li> */}
                    {/* <li className="has-children">
                      <a href="#">Brand</a>

                      <ul className="is-hidden">
                        <li className="go-back">
                          <a href="#0">Clothing</a>
                        </li>
                        <li className="see-all">
                          <a href="#">All Accessories</a>
                        </li>
                        <li className="has-children">
                          <a href="#0">Beanies</a>

                          <ul className="is-hidden">
                            <li className="go-back">
                              <a href="#0">Accessories</a>
                            </li>
                            <li className="see-all">
                              <a href="#">All Benies</a>
                            </li>
                            <li>
                              <a href="#">Caps &amp; Hats</a>
                            </li>
                            <li>
                              <a href="#">Gifts</a>
                            </li>
                            <li>
                              <a href="#">Scarves &amp; Snoods</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <a href="#0">Caps &amp; Hats</a>

                          <ul className="is-hidden">
                            <li className="go-back">
                              <a href="#0">Accessories</a>
                            </li>
                            <li className="see-all">
                              <a href="#">All Caps &amp; Hats</a>
                            </li>
                            <li>
                              <a href="#">Beanies</a>
                            </li>
                            <li>
                              <a href="#">Caps</a>
                            </li>
                            <li>
                              <a href="#">Hats</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Glasses</a>
                        </li>
                        <li>
                          <a href="#">Gloves</a>
                        </li>
                        <li>
                          <a href="#">Jewellery</a>
                        </li>
                        <li>
                          <a href="#">Scarves</a>
                        </li>
                      </ul>
                    </li>

                    <li className="has-children">
                      <a href="#">Bottoms</a>

                      <ul className="is-hidden">
                        <li className="go-back">
                          <a href="#0">Clothing</a>
                        </li>
                        <li className="see-all">
                          <a href="#">All Bottoms</a>
                        </li>
                        <li>
                          <a href="#">Casual Trousers</a>
                        </li>
                        <li className="has-children">
                          <a href="#0">Jeans</a>

                          <ul className="is-hidden">
                            <li className="go-back">
                              <a href="#0">Bottoms</a>
                            </li>
                            <li className="see-all">
                              <a href="#">All Jeans</a>
                            </li>
                            <li>
                              <a href="#">Ripped</a>
                            </li>
                            <li>
                              <a href="#">Skinny</a>
                            </li>
                            <li>
                              <a href="#">Slim</a>
                            </li>
                            <li>
                              <a href="#">Straight</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#0">Leggings</a>
                        </li>
                        <li>
                          <a href="#0">Shorts</a>
                        </li>
                      </ul>
                    </li>

                    <li className="has-children">
                      <a href="#">Jackets</a>

                      <ul className="is-hidden">
                        <li className="go-back">
                          <a href="#0">Clothing</a>
                        </li>
                        <li className="see-all">
                          <a href="#">All Jackets</a>
                        </li>
                        <li>
                          <a href="#">Blazers</a>
                        </li>
                        <li>
                          <a href="#">Bomber jackets</a>
                        </li>
                        <li>
                          <a href="#">Denim Jackets</a>
                        </li>
                        <li>
                          <a href="#">Duffle Coats</a>
                        </li>
                        <li>
                          <a href="#">Leather Jackets</a>
                        </li>
                        <li>
                          <a href="#">Parkas</a>
                        </li>
                      </ul>
                    </li>

                    <li className="has-children">
                      <a href="#">Tops</a>

                      <ul className="is-hidden">
                        <li className="go-back">
                          <a href="#0">Clothing</a>
                        </li>
                        <li className="see-all">
                          <a href="#">All Tops</a>
                        </li>
                        <li>
                          <a href="#">Cardigans</a>
                        </li>
                        <li>
                          <a href="#">Coats</a>
                        </li>
                        <li>
                          <a href="#">Polo Shirts</a>
                        </li>
                        <li>
                          <a href="#">Shirts</a>
                        </li>
                        <li className="has-children">
                          <a href="#0">T-Shirts</a>

                          <ul className="is-hidden">
                            <li className="go-back">
                              <a href="#0">Tops</a>
                            </li>
                            <li className="see-all">
                              <a href="#">All T-shirts</a>
                            </li>
                            <li>
                              <a href="#">Plain</a>
                            </li>
                            <li>
                              <a href="#">Print</a>
                            </li>
                            <li>
                              <a href="#">Striped</a>
                            </li>
                            <li>
                              <a href="#">Long sleeved</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Vests</a>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                </li>
              ))
            ) : (
              <li>
                <p className="text-center bg-danger text-white m-0">
                  Categories Not Found
                </p>
              </li>
            )}

            {/* <li className="has-children">
              <a href="#">Gallery</a>

              <ul className="cd-dropdown-gallery is-hidden">
                <li className="go-back">
                  <a href="#0">Menu</a>
                </li>
                <li className="see-all">
                  <a href="#">Browse Gallery</a>
                </li>
                <li>
                  <a className="cd-dropdown-item" href="#">
                    <img src="img/img.png" alt="Product Image" />
                    <h3>Product #1</h3>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item" href="#">
                    <img src="img/img.png" alt="Product Image" />
                    <h3>Product #2</h3>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item" href="#">
                    <img src="img/img.png" alt="Product Image" />
                    <h3>Product #3</h3>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item" href="#">
                    <img src="img/img.png" alt="Product Image" />
                    <h3>Product #4</h3>
                  </a>
                </li>
              </ul>
            </li>

            <li className="has-children">
              <a href="#">Services</a>
              <ul className="cd-dropdown-icons is-hidden">
                <li className="go-back">
                  <a href="#0">Menu</a>
                </li>
                <li className="see-all">
                  <a href="#">Browse Services</a>
                </li>
                <li>
                  <a className="cd-dropdown-item item-1" href="#">
                    <h3>Service #1</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-2" href="#">
                    <h3>Service #2</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-3" href="#">
                    <h3>Service #3</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-4" href="#">
                    <h3>Service #4</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-5" href="#">
                    <h3>Service #5</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-6" href="#">
                    <h3>Service #6</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-7" href="#">
                    <h3>Service #7</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-8" href="#">
                    <h3>Service #8</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-9" href="#">
                    <h3>Service #9</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-10" href="#">
                    <h3>Service #10</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-11" href="#">
                    <h3>Service #11</h3>
                    <p>This is the item description</p>
                  </a>
                </li>

                <li>
                  <a className="cd-dropdown-item item-12" href="#">
                    <h3>Service #12</h3>
                    <p>This is the item description</p>
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
        </nav>
      </div>
    </li>
  );
}
