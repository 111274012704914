import React from "react";

export default function Loader() {
  return (
    <div id="loader-1">
      {/* <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span> */}
      <div className="loader-prd"></div>
    </div>
  );
}
