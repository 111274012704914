import React from "react";
import { Link } from "react-router-dom";
import cart from "../../../Images/maskgroup-3.png";

export default function CartCounter({ counter }) {
  return (
    <Link to="/cart">
      <img src={cart} alt="Cart" />
      <span>{counter}</span>
    </Link>
  );
}
