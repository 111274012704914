import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import profile from "../../../Images/profile.png";
import heart from "../../../Images/heart.png";
import gift from "../../../Images/gift.png";
import logOut from "../../../Images/logout.png";
import pkg from "../../../Images/package.png";
import cartPic from "../../../Images/shopping-cart.png";

export default function MobileMenuFooter() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const locat = useLocation();

  const { wishlist_items_count, cartCount } = useSelector(
    (state) => state.stateVals
  );
  const navigate = useNavigate();
  const logout = async () => {
    await userActions.logOut("userLogOut");
    navigate("/");
  };
  return (
    <div className="container">
      <div className="top-logo-box-m">
        <div className="navigation">
          <ul>
            <li
              className={`list ${
                locat.pathname === "/view-reward" ? "active" : ""
              }`}
              data-color="#dc143c"
            >
              <Link to="/view-reward">
                <span className="icon">
                  <img src={gift} alt="" />
                </span>
                <span className="title">Rewards</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/order-history" ? "active" : ""
              }`}
              data-color="#dc143c"
            >
              <Link to="/order-history">
                <span className="icon">
                  <img src={pkg} alt="" />

                  {/* <i className="bi bi-box-seam"></i> */}
                </span>
                <span className="title">Orders</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/wishlist" ? "active" : ""
              }`}
              data-color="#0fbcf9"
            >
              <Link to="/wishlist">
                <span className="icon">
                  <img src={heart} alt="" />
                </span>
                <span className="title">Wishlist ({wishlist_items_count})</span>
              </Link>
            </li>
            <li
              className={`list ${locat.pathname === "/cart" ? "active" : ""}`}
              data-color="#05c46b"
            >
              <Link to="/cart">
                <span className="icon">
                  <img src={cartPic} alt="" />
                </span>
                <span className="title">Cart ({cartCount})</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/my-account" ? "active" : ""
              }`}
              data-color="#3c40c6"
            >
              <Link to="/my-account">
                <span className="icon">
                  <img src={profile} alt="" />
                </span>
                <span className="title">User</span>
              </Link>
            </li>
            <li className="list" data-color="#ffa801">
              <a onClick={logout}>
                <span className="icon">
                  <img src={logOut} alt="" />
                </span>
                <span className="title">Logout</span>
              </a>
            </li>
            <div className="indicator"></div>
          </ul>
        </div>
      </div>
    </div>
  );
}
