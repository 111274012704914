import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  useEffect(() => {
    let list = document.querySelectorAll(".list");
    for (let i = 0; i < list.length; i++) {
      list[i].onclick = (e) => {
        let j = 0;
        while (j < list.length) {
          list[j++].className = "list";
        }
        list[i].className = "list active";
      };
    }
  }, []);
  return (
    <>
      <div className="clearfix"></div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved by
                Elite Rewards.
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <a href="mailTo:help@eliterewards.biz">help@eliterewards.biz</a>{" "}
                and <a href="tel:8663548321">(866)354-8321</a> 8:00am to 5:00pm
                EST, M-F.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
