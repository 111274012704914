import React from "react";

import ImageLoader from "./ImageLoader";

import AddToWish from "./AddToWish";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../../Redux";
import { bindActionCreators } from "redux";
import UserListService from "../../../services/user-list.service";
import { showErrorToast } from "../../Admin/Includes/toastError";

export default function FeaturedProductBox({
  id,
  img,
  discription,
  points,
  product_number,
  product_type,
  product_image,
  product_model,
  product_name,
  product_points,
}) {
  const dispatch = useDispatch();

  const userActions = bindActionCreators(actionCreaters, dispatch);

  const { userAccessToken } = useSelector((state) => state.stateVals);
  const addToCart = async () => {
    try {
      const response = await UserListService.addInCart({
        product_id: id,
        product_number: product_number,
        cat_type: product_type,
        product_type: product_type,
        product_image: product_image,
        product_model: product_model,
        product_name: product_name,
        product_points: product_points,

        userAccessToken: userAccessToken,
      });

      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      userActions.cartCounter(
        response?.data?.response?.cart_items_count,
        "cartCounter"
      );
    } catch (err) {
      console.log(err);
      showErrorToast(err);
    }
  };
  return (
    <div className="featured-product-box">
      <div className="border img">
        <AddToWish
          id={id}
          product_number={product_number}
          product_type={product_type}
        />
        <div className="cart-add" onClick={addToCart} title="Add to Cart">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M6.30711 8.6963C5.73903 8.50258 5.32278 8.36121 4.90654 8.22246C4.91701 8.04444 4.92486 7.86643 4.93533 7.68841C5.67096 7.66485 6.41706 7.58369 7.13698 7.68317C7.23385 7.69626 7.33333 8.64918 7.2129 9.10731C6.72074 10.9582 6.16574 12.7933 5.58195 14.618C5.31231 15.4609 5.60028 15.7384 6.41968 15.7358C10.2575 15.7254 14.0954 15.7149 17.9332 15.7437C18.6898 15.7489 18.9254 15.3903 18.9751 14.7279C19.2578 10.8587 19.5275 6.98681 19.8521 3.12018C19.9333 2.13847 19.5092 1.93689 18.6505 1.94213C14.9409 1.96569 11.234 1.94998 7.52705 1.94998C7.22076 1.94998 6.89614 2.01281 6.61602 1.9238C6.34114 1.83741 6.11339 1.59656 5.86469 1.42378C6.1003 1.28242 6.33591 1.01539 6.57414 1.01539C10.8492 0.997067 15.1242 0.989213 19.3966 1.02586C20.3914 1.03372 20.9281 1.6751 20.8574 2.65943C20.5485 6.87686 20.2081 11.0917 19.86 15.3065C19.7814 16.262 19.1348 16.6809 18.2526 16.6861C14.1975 16.7044 10.1397 16.7228 6.08459 16.6783C4.78349 16.6652 4.25206 15.8405 4.6081 14.5421C5.13953 12.6153 5.7207 10.7042 6.30711 8.6963Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
            <path
              d="M10.8691 4.29301C10.9084 4.68307 10.9686 5.07314 10.9791 5.46321C11.0079 6.54702 10.8979 7.65177 11.0602 8.71463C11.2958 10.2513 12.2069 10.61 13.2854 9.51047C14.5525 8.21723 15.597 6.70409 16.7358 5.28257C17.0212 4.92654 17.2882 4.55741 17.5657 4.19353C17.6887 4.2328 17.8118 4.27206 17.9374 4.30871C17.906 4.65951 18.0029 5.10455 17.8223 5.34278C16.5735 7.00515 15.4112 8.76437 13.9478 10.2199C12.2749 11.8823 10.589 11.22 10.1204 8.89265C9.80626 7.32977 9.79579 5.77735 10.2827 4.28254C10.4791 4.28777 10.6728 4.29039 10.8691 4.29301Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
            <path
              d="M6.2778 21.5528C5.21755 21.5266 4.45312 20.7648 4.45312 19.736C4.45312 18.5029 5.68877 17.3013 6.92442 17.338C7.96111 17.3668 8.72553 18.1521 8.72815 19.1888C8.73077 20.4925 7.5946 21.5868 6.2778 21.5528ZM8.24122 19.3145C7.5658 18.8328 7.08673 18.3145 6.91657 18.4035C6.37204 18.6836 5.94009 19.1862 5.46625 19.6051C5.76993 19.9349 6.19141 20.5946 6.35895 20.5344C6.93489 20.3328 7.4192 19.8773 8.24122 19.3145Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
            <path
              d="M16.1611 21.5554C15.0695 21.5528 14.3443 20.8538 14.3208 19.7857C14.2946 18.5056 15.4334 17.338 16.7109 17.3432C17.7869 17.3459 18.601 18.1941 18.5644 19.2753C18.5225 20.503 17.4099 21.5581 16.1611 21.5554ZM16.7371 17.8249C16.0852 18.6208 15.5904 19.0606 15.3548 19.613C15.2789 19.7884 15.8941 20.257 16.1926 20.5921C16.6454 20.1549 17.1795 19.77 17.5067 19.2517C17.6115 19.092 17.1586 18.5763 16.7371 17.8249Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
            <path
              d="M5.52035 5.24065C4.78472 5.24065 4.04648 5.2773 3.31608 5.21971C3.07786 5.20138 2.86057 4.93698 2.63281 4.78776C2.83177 4.60974 3.0255 4.2825 3.22969 4.27465C4.78472 4.23014 6.34237 4.23014 7.90002 4.27203C8.10945 4.27727 8.31365 4.59403 8.51785 4.7642C8.29794 4.92127 8.08589 5.20138 7.85552 5.21709C7.08062 5.27469 6.29787 5.24065 5.52035 5.24065Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
            <path
              d="M2.16758 2.16466C1.73825 1.88716 1.36912 1.64894 1 1.40809C1.40577 1.26934 1.82202 1.00231 2.21994 1.02588C2.63619 1.05206 3.03672 1.35311 3.44512 1.53375C3.0629 1.72224 2.67807 1.91334 2.16758 2.16466Z"
              fill="#23418D"
              stroke="#23418D"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="featured-product-box-content">
          <ImageLoader
            src={img}
            className="img-product"
            alt="product-img"
            id={id}
            leadingHeight={110}
            leadingWidth="100%"
          />

          <p className="product-text">{discription}</p>
          <p className="product-point">
            Points: <span>{points?.toLocaleString("en-US")}</span>
          </p>
          {/* <button className="button" onClick={addToCart}>
            <i className="bi bi-basket"></i>Add to Cart
          </button> */}
        </div>
      </div>
    </div>
  );
}
