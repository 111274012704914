import React from "react";
import Points from "../../../Images/image89.png";
import Redeemed from "../../../Images/image88.png";
import { useSelector } from "react-redux";

export default function PointsBalance() {
  const { points, redeemed_point } = useSelector((state) => state.stateVals);
  return (
    <ul className="top-user">
      <li>
        <img src={Points} alt="Points" /> Points Balance:<span> {points}</span>
      </li>
      <li>
        <img src={Redeemed} alt="Redeemed" /> Redeemed Points:
        <span> {redeemed_point}</span>
      </li>
    </ul>
  );
}
