// toastUtils.js
import { toast } from "react-toastify";

export function showErrorToast(err) {
  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  if (
    err?.response?.data?.error &&
    typeof err?.response?.data?.error === "string"
  ) {
    toast.warn(err.response.data.error, toastOptions);
  } else if (
    err?.response?.data?.message &&
    typeof err?.response?.data?.message === "string"
  ) {
    toast.warn(err.response.data.message, toastOptions);
  } else {
    toast.error("Something went wrong, try again", {
      ...toastOptions,
      theme: "colored",
    });
  }
}
