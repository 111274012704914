import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Roots from "./Roots";

function App() {
  const router = createBrowserRouter([{ path: "*", element: <Roots /> }]);

  return <RouterProvider router={router} />;
}

export default App;
