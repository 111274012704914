import React, { useEffect, Suspense, lazy } from "react";
import "select2/dist/css/select2.min.css";
import "select2";

import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./App.css";

import { Route, Routes, useLocation } from "react-router-dom";

import NotFound from "./NotFound";
import axios from "axios";
import Error429 from "./Error429";

import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "./Redux";
import { bindActionCreators } from "redux";
import MobileMenuFooter from "./Components/User/Includes/MobileMenuFooter";
import SearchedProducts from "./Components/User/Search/SearchedProducts";

const Login = lazy(() => import("./Components/User/Login/Login"));
const ForgotPass = lazy(() => import("./Components/User/Login/ForgotPass"));
const ResetPass = lazy(() => import("./Components/User/Login/ResetPass"));

const FirstPage = lazy(() => import("./Components/User/FirstPage/FirstPage"));
const ViewRewards = lazy(() =>
  import("./Components/User/ViewRewards/ViewRewards")
);
const AllProducts = lazy(() =>
  import("./Components/User/AllProducts/AllProducts")
);
const ViewProducts = lazy(() =>
  import("./Components/User/AllProducts/ViewProducts")
);

const ProductDetail = lazy(() =>
  import("./Components/User/ProductDetail/ProductDetail")
);

const OrderHistory = lazy(() => import("./Components/User/Order/OrderHistory"));
const RefundOrderHistory = lazy(() =>
  import("./Components/User/Order/RefundOrderHistory")
);

const WishList = lazy(() => import("./Components/User/Order/WishList"));
const WishListNew = lazy(() => import("./Components/User/Order/WishListNew"));

const Cart = lazy(() => import("./Components/User/Order/Cart"));
const CartNew = lazy(() => import("./Components/User/Order/CartNew"));

const Profile = lazy(() => import("./Components/User/Profile/Profile"));
const Contact = lazy(() => import("./Components/User/Contact/Contact"));
const Help = lazy(() => import("./Components/User/Contact/Help"));

// admin component down
const AddBadges = lazy(() => import("./Components/Admin/Badges/AddBadges"));
const EditBadges = lazy(() => import("./Components/Admin/Badges/EditBadges"));
const ManageBadges = lazy(() =>
  import("./Components/Admin/Badges/ManageBadges")
);

const ManageOrders = lazy(() =>
  import("./Components/Admin/ManageOrders/ManageOrders")
);
const AddParticipant = lazy(() =>
  import("./Components/Admin/Participant/AddParticipant")
);
const EditParticipant = lazy(() =>
  import("./Components/Admin/Participant/EditParticipant")
);

const ViewParticipant = lazy(() =>
  import("./Components/Admin/Participant/ViewParticipant")
);
const ViewAllParticipant = lazy(() =>
  import("./Components/Admin/Participant/ViewAllParticipant")
);
const ParticipantInfo = lazy(() =>
  import("./Components/Admin/Participant/ParticipantInfo")
);

export default function Roots() {
  const locat = useLocation();
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const { userAccessToken } = useSelector((state) => state.stateVals);

  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        } else if (error?.response?.status === 401 && locat.pathname !== "/") {
          toast.error("Unauthorized!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              window.location.href = "/";
            },
          });
          userActions.logOut("userLogOut");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const backToTop = () => {
      return (
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        ),
        !1
      );
    };
    backToTop();
  }, [locat.pathname]);

  return (
    <>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Login />} caseSensitive={true} />
          <Route
            path="/forgot-password"
            element={<ForgotPass />}
            caseSensitive={true}
          />
          <Route
            path="/account/reset-password/:token"
            element={<ResetPass />}
            caseSensitive={true}
          />
          <Route
            path="/first-page"
            element={<FirstPage />}
            caseSensitive={true}
          />
          <Route
            path="/view-reward"
            element={<ViewRewards />}
            caseSensitive={true}
          />
          <Route
            path="/all-products/:type"
            element={<AllProducts />}
            caseSensitive={true}
          />
          <Route
            path="/all-products/:type/:category"
            element={<AllProducts />}
            caseSensitive={true}
          />
          <Route
            path="/view-products/:name/:category"
            element={<ViewProducts />}
            caseSensitive={true}
          />
          <Route
            path="/view-products/:name/:category/:subcategory"
            element={<ViewProducts />}
            caseSensitive={true}
          />
          <Route
            path="/product-detail/:id"
            element={<ProductDetail />}
            caseSensitive={true}
          />
          <Route
            path="/order-history"
            element={<OrderHistory />}
            caseSensitive={true}
          />
          <Route
            path="/refund-order-history"
            element={<RefundOrderHistory />}
            caseSensitive={true}
          />
          <Route
            path="/wishlist"
            element={<WishListNew />}
            caseSensitive={true}
          />
          <Route
            path="/wishlist-new"
            element={<WishList />}
            caseSensitive={true}
          />
          <Route path="/cart" element={<CartNew />} caseSensitive={true} />
          <Route path="/cart-new" element={<Cart />} caseSensitive={true} />
          <Route path="/help" element={<Help />} caseSensitive={true} />
          <Route
            path="/contact-us"
            element={<Contact />}
            caseSensitive={true}
          />
          <Route
            path="/my-account"
            element={<Profile />}
            caseSensitive={true}
          />
          <Route
            path="/search-product"
            element={<SearchedProducts />}
            caseSensitive={true}
          />
          {/* admin routes  */}
          <Route
            path="/add-badges"
            element={<AddBadges />}
            caseSensitive={true}
          />
          <Route
            path="/edit-badges/:id"
            element={<EditBadges />}
            caseSensitive={true}
          />
          <Route
            path="/view-badges"
            element={<ManageBadges />}
            caseSensitive={true}
          />
          <Route
            path="/manage-orders"
            element={<ManageOrders />}
            caseSensitive={true}
          />
          <Route
            path="/add-participant"
            element={<AddParticipant />}
            caseSensitive={true}
          />
          <Route
            path="/edit-participant/:id"
            element={<EditParticipant />}
            caseSensitive={true}
          />
          <Route
            path="/view-participants"
            element={<ViewParticipant />}
            caseSensitive={true}
          />
          <Route
            path="/view-all-participans"
            element={<ViewAllParticipant />}
            caseSensitive={true}
          />
          <Route
            path="/participan-info"
            element={<ParticipantInfo />}
            caseSensitive={true}
          />
          <Route
            path="/participant-info/:id"
            element={<ParticipantInfo />}
            caseSensitive={true}
          />

          <Route
            path="/error-429"
            element={<Error429 />}
            caseSensitive={true}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {locat.pathname !== "/forgot-password" &&
      locat.pathname !== "/" &&
      !locat.pathname.startsWith("/account/reset-password/") &&
      userAccessToken ? (
        <MobileMenuFooter />
      ) : null}
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="white-screen"></div>
      ) : null}
    </>
  );
}
