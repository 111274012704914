import React, { useEffect, useRef, useState } from "react";
import Header from "../Includes/Header";
import Footer from "../Includes/Footer";
import { Link, useSearchParams } from "react-router-dom";

import FeaturedProductBox from "../Includes/FeaturedProductBox";
import { useSelector } from "react-redux";
import UserListService from "../../../services/user-list.service";

export default function SearchedProducts() {
  const [searchParams] = useSearchParams();

  // Access the 'search' parameter value
  const searchValue = searchParams.get("search");
  const { userAccessToken } = useSelector((state) => state.stateVals);
  const [prd, setPrd] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [nextData, setNextData] = useState(true);
  const [sortBy, setSortBy] = useState("name");
  const [orderBy, setOrderBy] = useState("asc");

  const loaderRef = useRef(null);
  const getProList = async (page) => {
    try {
      setLoader(true);
      const res = await UserListService.searchInHeader(
        userAccessToken,
        searchValue,
        page,
        sortBy,
        orderBy
      );

      setPrd((prev) => [...prev, ...res?.data?.response.data]);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      setNextData(false);

      if (page === 1) {
        setPrd([]);
      }
    }
  };
  useEffect(() => {
    if (userAccessToken) {
      setPrd([]);
      setNextData(true);
      setSortBy("name");
      setOrderBy("asc");
      getProList(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (userAccessToken && prd.length) {
      setPrd([]);
      setNextData(true);
      getProList(1);
    }
  }, [sortBy, orderBy]);

  useEffect(() => {
    if ((userAccessToken, nextData)) {
      if (page !== 1) {
        getProList(page);
      }
    }
  }, [page]);
  useEffect(() => {
    let observer;

    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loader) {
        setPage((prev) => prev + 1); // Load next page when user reaches the end
      }
    });
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loader]);
  return (
    <div className="user-inner">
      <Header />

      <div className="container">
        <div className="heading-banner">
          <div className="heading-content">
            <div className="heading-text">
              Search: <span className="text-capitelize">{searchValue}</span>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/view-reward">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Search
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="all-products-section">
          <div className="row mt-4 pt-2">
            <div className="col-lg-3">
              <div className="input-wraper mb-lg-0">
                <label>Sort By</label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="23"
                      viewBox="0 0 25 23"
                      fill="none"
                    >
                      <path
                        d="M16 18L16 6M16 6L20 10.125M16 6L12 10.125"
                        stroke="#797a7a"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 6L8 18M8 18L12 13.875M8 18L4 13.875"
                        stroke="#797a7a"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <select
                    className="form-select"
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                  >
                    <option value="name">Name</option>

                    <option value="points">Points</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-3 ms-auto">
              <div className="input-wraper m-0">
                <label>Order By</label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-list-columns-reverse"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                      />
                    </svg>
                  </span>
                  <select
                    className="form-select"
                    value={orderBy}
                    onChange={(e) => {
                      setOrderBy(e.target.value);
                    }}
                  >
                    <option value="asc">Ascending</option>

                    <option value="desc">Descending</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {prd?.length ? (
          <div className="all-products-section">
            {prd.map((product) => (
              <div className="all-product-box" key={product.id}>
                <FeaturedProductBox
                  img={product.image}
                  discription={product.name}
                  id={product.id}
                  points={product.points}
                  product_number={product.product_number}
                  product_type={product.product_type}
                  product_image={product.image}
                  product_model={product.model_number}
                  product_name={product.name}
                  product_points={product.points}
                />
              </div>
            ))}

            <div className="clearfix"></div>
          </div>
        ) : !loader ? (
          <p className="text-white bg-danger p-3 text-center mt-3">
            No Product Found
          </p>
        ) : null}
        <div ref={loaderRef}>
          {loader && (
            // <div className="content-loader">
            //   <div className="sk-folding-cube active p-0">
            //     <div className="loader-42"></div>
            //   </div>
            // </div>
            <div className="loader-prd"></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
