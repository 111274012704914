import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import UserListService from "../../../services/user-list.service";
import { showErrorToast } from "../../Admin/Includes/toastError";

export default function AddToWish({ id, product_number, product_type }) {
  const [underProgress, setUnderProgress] = useState(false);
  const dispatch = useDispatch();

  const userActions = bindActionCreators(actionCreaters, dispatch);

  const { userAccessToken, wishIdz } = useSelector((state) => state.stateVals);
  const addToWish = async () => {
    try {
      setUnderProgress(true);
      const response = await UserListService.addInWish({
        product_id: id,
        product_number: product_number,
        cat_type: product_type,
        userAccessToken: userAccessToken,
      });

      toast.success("Product added to Wishlist.", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      await userActions.wishCounter(
        {
          userAccessToken: userAccessToken,
          count: response?.data?.wishlist_count,
          idz: response?.data?.wishlist_ids,
        },
        "wishCount"
      );
      setUnderProgress(false);
    } catch (err) {
      console.log(err);
      setUnderProgress(false);

      showErrorToast(err);
    }
  };

  const deleteFromList = async () => {
    try {
      setUnderProgress(true);
      const response = await UserListService.deleteById(userAccessToken, id);
      toast.success("Product removed from Wishlist.", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      await userActions.wishCounter(
        {
          userAccessToken: userAccessToken,
          count: response?.data?.wishlist_count,
          idz: response?.data?.wishlist_ids,
        },
        "wishCount"
      );
      setUnderProgress(false);
    } catch (err) {
      setUnderProgress(false);

      showErrorToast(err);
    }
  };

  return (
    <div
      className="favorites-heart"
      onClick={
        underProgress
          ? null
          : wishIdz?.includes(parseInt(id))
          ? deleteFromList
          : addToWish
      }
      title={`${
        wishIdz?.includes(parseInt(id))
          ? "Remove to Wishlist"
          : "Add to Wishlist"
      }`}
    >
      <i
        className={`bi  ${
          wishIdz?.includes(id) ? "bi-heart-fill red" : "bi-heart"
        }`}
      ></i>
    </div>
  );
}
