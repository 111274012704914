import React from "react";

export default function Error429() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>2</span>9
          </h1>
        </div>
        <h2>
          You've made too many requests recently. Please wait and try your
          request again later.
        </h2>
        <a href="/" className="btn btn-block btn-primary nm-btn">
          Back To Homepage
        </a>
      </div>
    </div>
  );
}
