import React from "react";
import user from "../../../Images/Maskgroup-1.png";
import logoutIco from "../../../Images/Maskgroup.png";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../../Redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Points from "../../../Images/image89.png";

export default function LogoutBox() {
  const { name, points, role } = useSelector((state) => state.stateVals);
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const logout = async () => {
    await userActions.logOut("userLogOut");
    navigate("/");
  };
  return (
    <li className="logout-box">
      <ul className="top-user">
        {role === "admin" ? (
          <li className="admin-points">
            <img src={Points} alt="Points" /> Points Balance:
            <span> {points}</span>
          </li>
        ) : null}
        <li>
          <img src={user} alt="user" />
          Welcome: <span className="text-capitalize">{name}</span>
        </li>
        <li style={{ cursor: "pointer" }} onClick={logout}>
          <img src={logoutIco} alt="logout" /> Logout
        </li>
      </ul>
    </li>
  );
}
